<template>
  <h1>Dashboard</h1>
</template>

<script>
export default {
  name: 'Dashboard'
};
</script>

<style></style>
